import styled from 'styled-components';

export const SpinningLoader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5em 0;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.main.colors.lightGreen};
`;
