import React from 'react';
import * as S from './styled';

const Loader = () => {
  return (
    <S.SpinningLoader>
      <i className="bx bx-loader-alt bx-lg bx-spin"></i>
    </S.SpinningLoader>
  );
};

export default Loader;
