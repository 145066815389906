const { differenceInCalendarDays } = require('date-fns');

const formatDate = (dateValue) => {
  const [year, month, day] = dateValue.split('-');
  return `Expira em ${differenceInCalendarDays(new Date(year, month, day), new Date()).toString()} dias`;
}
  
const formatLocation = location => JSON.parse(location).cidade;

const shuffleArray = (arr) => {
  let currentIndex = arr.length;
  let temporaryValue, randomIndex;
  
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    
    temporaryValue = arr[currentIndex];
    arr[currentIndex] = arr[randomIndex];
    arr[randomIndex] = temporaryValue;
  }
  
  return arr;
};

module.exports = {
  formatDate,
  formatLocation,
  shuffleArray,
}
