import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import { VAGAS_IMAGES_URL_PREFIX } from 'utils';
import * as S from './styled';

const returnCardAnchorTitle = ({
  isHandcap,
  title,
  location,
  type,
  companyName,
}) => {
  let vacancyTitle = isHandcap
    ? `Ir para vaga(PCD): ${title}`
    : `Ir para vaga: ${title}`;
  if (!!location) vacancyTitle = `${vacancyTitle} - ${location}`;
  if (!!type) vacancyTitle = `${vacancyTitle} - ${type}`;
  !!companyName.length
    ? (vacancyTitle = `${vacancyTitle} - ${companyName}`)
    : (vacancyTitle = `${vacancyTitle} - Confidencial`);
  return vacancyTitle;
};

const VacancyCard = ({
  dataTestid,
  companyName,
  companyLogo,
  title,
  type,
  location,
  vacancyDate,
  isHandcap,
  vacancyUrl,
}) => {
  const vacancyTitle = returnCardAnchorTitle({
    isHandcap,
    title,
    location,
    type,
    companyName,
  });

  return (
    <S.StyledCard data-testid={dataTestid}>
      <S.StyledCardLink
        href={vacancyUrl}
        title={vacancyTitle}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="vacancycard-anchor"
      >
        <S.StyledCardHeader aria-hidden="true">
          {companyLogo !== 'undefined' && companyName.length ? (
            <S.StyledFigure>
              <img
                src={`${VAGAS_IMAGES_URL_PREFIX}${companyLogo}`}
                alt={companyName}
                data-testid="vacancycard-companyLogo"
              />
            </S.StyledFigure>
          ) : (
            <S.LogoPlaceholder>
              <i
                className="bx bx-building bx-sm"
                data-testid="vacancycard-companyLogoGeneric"
              ></i>
            </S.LogoPlaceholder>
          )}
          {isHandcap && (
            <S.BoxIconWrapper>
              <i
                className="bx bx-handicap bx-sm"
                data-testid="vacancycard-iconHandicap"
              ></i>
            </S.BoxIconWrapper>
          )}
        </S.StyledCardHeader>
        <S.StyledCardBody aria-hidden="true">
          <h3 data-testid="vacancycard-title">{title}</h3>
          <p data-testid="vacancycard-companyName">
            {!!companyName.length ? companyName : 'Confidencial'}
          </p>
          <p data-testid="vacancycard-hierarchy">{type}</p>
        </S.StyledCardBody>
        <S.StyledCardFooter aria-hidden="true">
          <S.StyledCardFooterColumn>
            {!!location && (
              <Fragment>
                <i className="bx bx-map bx-sm"></i>
                <span data-testid="vacancycard-companyLocation">
                  &nbsp; {location}
                </span>
              </Fragment>
            )}
          </S.StyledCardFooterColumn>
          <S.StyledCardFooterColumn>
            {!!vacancyDate.length && (
              <Fragment>
                <i className="bx bx-time-five bx-sm"></i>
                <span data-testid="vacancycard-date">&nbsp; {vacancyDate}</span>
              </Fragment>
            )}
          </S.StyledCardFooterColumn>
        </S.StyledCardFooter>
      </S.StyledCardLink>
    </S.StyledCard>
  );
};

VacancyCard.propTypes = {
  companyName: string,
  companyLogo: string.isRequired,
  title: string.isRequired,
  type: string,
  location: string,
  vacancyDate: string,
  isHandcap: bool,
  vacancyUrl: string.isRequired,
};

VacancyCard.defaultProps = {
  companyName: '',
  type: '',
  location: '',
  vacancyDate: '',
  isHandcap: false,
};

export default VacancyCard;
