import React, { useEffect, useState } from 'react';
import uuid from 'uuid';
import axios from 'axios';

import { formatDate, formatLocation } from 'api/helpers';
import VacancyCard from 'components/VacancyCard';
import LinkButton from 'components/LinkButton';
import Loader from 'components/Loader';
import { Container } from 'styles';
import * as S from 'styles/Home/styled';

const FeaturedVacancies = () => {

  const [featuredVacancies, setFeaturedVacancies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const URI = window.location.href;
    const API_URI = `${URI}api/featured-vacancies`;

    (async () => {
      try {
        const { data } = await axios.get(API_URI);
        let vacancies = [];
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          const {
            nome_da_empresa,
            logo_da_divisao_homepages,
            cargo_stripped,
            exclusiva_para_deficiente,
            hierarquia,
            url_navegacao_vagascombr,
          } = item;
          const local_da_vaga = formatLocation(item.local_da_vaga);
          const data_de_expiracao = formatDate(item.data_de_expiracao);

          vacancies.push({
            nome_da_empresa,
            logo_da_divisao_homepages,
            exclusiva_para_deficiente,
            cargo_stripped,
            hierarquia,
            local_da_vaga,
            data_de_expiracao,
            url_navegacao_vagascombr
          });
        });
        setFeaturedVacancies(vacancies);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    (!!featuredVacancies.length) && (
        <S.StyledSection isGrey>
        <Container>
          <S.SectionTitle>Vagas de tecnologia em destaque</S.SectionTitle>
          {(isLoading) && <Loader />}
          <S.VacancyCardsContainer>     
            {featuredVacancies.map((vacancy, index) => (
                <VacancyCard
                  key={uuid.v4()}
                  dataTestid={`vacancycard-${index}`}
                  companyName={vacancy.nome_da_empresa}
                  companyLogo={`${vacancy.logo_da_divisao_homepages}`}
                  isHandcap={vacancy.exclusiva_para_deficiente}
                  title={vacancy.cargo_stripped}
                  type={vacancy.hierarquia}
                  location={vacancy.local_da_vaga}
                  vacancyDate={vacancy.data_de_expiracao}
                  vacancyUrl={vacancy.url_navegacao_vagascombr}
                />
              )
            )}
          </S.VacancyCardsContainer>
          <LinkButton
            type="primary"
            size="lg"
            label="Veja mais Vagas"
            url="https://www.vagas.com.br/vagas-de-tecnologia"
            target="_self"
            data-testid="btn-moreVacancies"
          />
        </Container>
      </S.StyledSection>
    )
  )
};

export default FeaturedVacancies;
